import React from "react";

//Material-UI components
import { Box, Typography } from "@mui/material";
import Select from "react-select";

import ReactCountryFlag from "react-country-flag";

//Custom styles for components
import { customStyles , usersCountryDropdown } from "../../components/common/CustomStyle";

import {
  countriesOptions,
  customFilterOption,
  dropDownOptions,
} from "../../constants"



const FilterSection = ({
  countries,
  clientList,
  // handleSelectClient,
  // selectedClient,
  handleSelectMember,
  // selectedClient,
  selectedMember,

  members,

  loggedInUserDetails,

  selectedCountry,
  handleCountryChange,
  handleActiveTab,
  activeTab,
  
  handleNewUser,

}) => {
  return (
    <Box
      sx={{ width: "100%" }}
      display={"flex"}
      alignItems={"center"}
      height={30}
    >
      <Typography
        variant="span"
        className="view-text me-2 filter-by"
        style={{
          paddingLeft: "0px !important",
          paddingRight: "0px !important",
        }}
      >
        Filter by
      </Typography>
      <Box className="d-flex align-items-center filter-data">
        <Box sx={{ fontSize: '14px' }} className={activeTab === "All"?"active":""} onClick={()=> handleActiveTab("All")}>
          All
        </Box>
        {loggedInUserDetails?.extension_Role === "HqAdmin" &&
        // <select
        //   class="form-select map-select users-select"
        //   aria-label="Default select example"
        //   value={selectedMember}
        //   // value={selectedClient}
        //   // onChange={handleSelectClient}
        //   onChange={handleSelectMember}
        //   style={{ width: "110px" , maxWidth:'110px' , whiteSpace:'nowrap' , textOverflow:'ellipsis' , paddingRight:'25px' }}
        //   maxMenuHeight={10}
        // >
        //   <option value="" selected disabled>Member</option>
        //   {members?.map((item, key) => {
        //     return <option value={item.id}>{item.memberName}</option>;
        //   })}
        // </select>

          <Typography
            variant="span"
            sx={{ width: "140px", zIndex: "100 !important" ,fontSize: '14px'}}
          >
            <Select
              options={dropDownOptions(members, "id", "memberName")}
              placeholder="Member"
              onChange={handleSelectMember}
              value={selectedMember}
              styles={{ ...customStyles(), ...usersCountryDropdown() }}
              className="users-country-select"
              maxMenuHeight={300}
            />
          </Typography>

 } 
        <Typography variant="span" sx={{ width: "200px"  , zIndex:"100 !important",fontSize: '14px'}}>
          <Select
          className="users-country-select"
            options={countriesOptions(countries)}
            placeholder="Country"
            filterOption={customFilterOption}
            onChange={handleCountryChange}
            value={selectedCountry}
            styles={{...customStyles() , ...usersCountryDropdown()}}
            dropdownPosition="auto"
            maxMenuHeight={300}
          />
        </Typography>

      

        <Box sx={{ fontSize: '14px' }} className= {activeTab === "NewUsers"?"active users-new-user":"users-new-user"} onClick={()=> handleActiveTab("NewUsers")}>New users</Box>
      </Box>
    </Box>
  );
};
export default FilterSection;
